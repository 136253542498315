<template>
    <el-dialog
      v-model="dialogVisible"
      custom-class="customWidth"
      center
      :show-close="false"
      title="パスワード再発行"
      class="dialog"
      >
      <div class="content">
        <el-form ref="user_form" :model="userForm"  :inline='true' :rules="rules">
          <el-form-item label="ユーザー名：" :show-message="false" prop="username">
            <el-input v-model="userForm.username"></el-input>
          </el-form-item>
          <el-form-item label="メールアドレス：" :show-message="false" prop="email">
            <el-input v-model="userForm.email"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button
          class="button"
          type="primary"
          @click="onResetPsw"
          :loading="loading"
          >再発行</el-button
        >
      </span>
    </template>
    </el-dialog>
</template>

<script>
import { resetPsw } from '@/api/ss102m'

export default {
  name: '',
  emits: ['onSuccess'],
  data() {
    return {
      rules: {
        username: [
          { required: true, message: '入力してください', trigger: 'blur' },
          {
            message: '英数字を入力てください',
            trigger: 'blur',
            pattern: /^[a-zA-Z0-9]+$/,
            transform(value) {
              return value
            }
          }
        ],
        email: [
          { required: true, message: '入力してください', trigger: 'blur' },
          {
            message: 'メールアドレスを入力てください',
            trigger: 'blur',
            pattern: /^[\u002Ea-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            transform(value) {
              return value === '' ? '1@a.com' : value
            }
          }
        ]
      },
      dialogVisible: false,
      loading: false,
      userForm: {
        username: '',
        email: ''
      }
    }
  },
  methods: {
    onShowDialog() {
      this.dialogVisible = true
    },
    onResetPsw() {
      this.$refs.user_form.validate(valid => {
        if (valid) {
          this.loading = true
          resetPsw(this.userForm).then(response => {
            this.$message({
              message: '「パスワードは再発行しました、登録メールアドレスに「パスワード」をメールで送信いたします',
              type: 'success'
            })
            this.loading = false
            this.dialogVisible = false
          }).catch(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import './screen_767.scss';
  @import './screen_768.scss';
</style>

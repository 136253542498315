<template>
    <HeaderComponent :showLogout ='false'/>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">

      <div class="title-container">
        <h3 class="title">ログイン</h3>
      </div>

      <el-form-item prop="username">
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="メール"
          name="username"
          type="text"
          tabindex="1"
          auto-complete="on"
          class="input"
        />
      </el-form-item>

      <!-- <el-row>
        <el-col align ='right'><el-link type="primary" @click="onShowResetPsw" class="a_psw">パスワードを忘れた方</el-link></el-col>
      </el-row> -->
      <el-form-item prop="password">
        <el-input
          :key="passwordType"
          ref="password"
          v-model="loginForm.password"
          :type="passwordType"
          placeholder="パスワード"
          name="password"
          tabindex="2"
          auto-complete="on"
          class="input"
        />
      </el-form-item>

      <el-button :loading="loading" type="primary" class="button"  @click="handleLogin">ログイン</el-button>

    </el-form>
    <ResetPswComponent ref="resetpsw"/>
  </div>
      <FotterComponent />
</template>

<script>
import ResetPswComponent from '@/components/ResetPsw/index'
import HeaderComponent from '@/components/Header/index'
import FotterComponent from '@/components/Fotter/index'
export default {
  name: 'Login',
  components: {
    ResetPswComponent,
    HeaderComponent,
    FotterComponent
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error(' '))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: ' ' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword, message: ' ' }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', this.loginForm).then(() => {
            if (this.$store.getters.roles.includes('Manager')) {
              this.$router.push({ path: this.redirect || '/rs101m/rs102m' })
            } else {
              this.$router.push({ path: this.redirect || '/in101m' })
            }
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    onShowResetPsw() {
      this.$refs.resetpsw.onShowDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './screen_767.scss';
  @import './screen_768.scss';
</style>

<template>
  <header id="header_grc">
      <div class="header_wrap_grc">
          <div class="logo"><a href="/"><img src="@//assets/images/img_logo.png" alt="グランシーノ"></a></div>
          <nav id="gnavi_grc">
              <ul class="menu_grc">
                  <li><a href="https://gransea-n.jp/company/" target="_blank">会社概要</a></li>
                  <li><a href="https://gransea-n.jp/terms_conditions/terms_conditions.php" target="_blank">約款</a></li>
                  <li><a href="https://gransea-n.jp/terms_conditions/travel_conditions.php" target="_blank">ご旅行条件書</a></li>
              </ul>
          </nav>
          <ul class="contact_grc">
              <!--<li><a href="#">ログイン</a></li>-->
              <li><a href="/in101m">マイページ</a></li>
              <li v-if="showLogout" ><a @click="logout" >ログアウト</a></li>
          </ul>
          <div class="icon_menu_grc"><span></span></div>
      </div>
  </header>

</template>

<script>
export default {
  name: '',
  props: {
    showLogout: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    }
  },

  methods: {
    async logout() {
      await this.$store.dispatch('user/logout')
      // this.$router.push(`/ss102m?redirect=${this.$route.fullPath}`)
      this.$router.push(`/ss102m`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './screen_767.scss';
@import './screen_768.scss';
</style>
<style  scoped>
    @import "../../common/animate.scss";
    @import "../../common/styles.scss";
    @import "../../common/responsive.scss";
</style>

